// 郵便番号(class=post_number1およびpost_number2)入力時に、都道府県(class=prefecture)、市区町村(class=address1)を自動入力する
// 郵便番号入力フィールドをハイフンで区切っている場合もclass名を1,2で設定することで使用可能
$(function () {
  $(".post_number1").on("change", function () {
    get_address();
  });

  $(".post_number2").on("change", function () {
    get_address();
  });

  function get_address() {
    post_number1 = $(".post_number1").get(0).value;
    // post_number2が存在している時のみ、郵便番号の結合処理をおこなう
    post_number = $(".post_number2").length ? post_number1 + $(".post_number2").get(0).value : post_number1;

    if (post_number.match(/^[0-9]{7}$/)) {
      $.getJSON("/api/v1/get_addresses", "post_number=" + post_number, (json) => {
        if (json.pref_cd != "") {
          $(".prefecture").get(0).value = json.pref_cd;
          $(".address1").get(0).value = json.address;
        }
      });
    }
  }
});
